var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eventDetail" },
    [
      _c("cms-text-link-block", [
        _c("div", {
          staticClass: "eventDetail__text",
          domProps: { innerHTML: _vm._s(_vm.event.text) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "eventDetail__info" }, [
        _c("ul", [
          _vm.event.exhibitionName
            ? _c(
                "li",
                [
                  _c("icon", { attrs: { icon: "exhibition" } }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.event.exhibitionName) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.event.location || _vm.event.booth
            ? _c(
                "li",
                [
                  _c("icon", { attrs: { icon: "mapPin" } }),
                  _vm._v("\n                " + _vm._s(_vm.event.location)),
                  _vm.event.location && _vm.event.booth
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.event.booth) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.event.date || _vm.event.dateEnd
            ? _c(
                "li",
                [
                  _c("icon", { attrs: { icon: "calendar" } }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.renderDate(_vm.event)) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.event.externalLink
            ? _c(
                "li",
                [
                  _c(
                    "link-wrap",
                    {
                      staticClass: "eventDetail__hover",
                      attrs: { link: _vm.event.externalLink },
                    },
                    [
                      _c("icon", {
                        staticStyle: { transform: "rotateZ(-45deg)" },
                        attrs: { icon: "arrowRight" },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("readMore")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "eventDetail__contentelements" },
        [
          _c("content-element-children", {
            attrs: { children: _vm.event.content.children },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }