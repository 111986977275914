




















































import {AppStoreKeys} from '../../../../AppStoreKeys';
import Icon from '../../../../Component/Misc/Icon/Icon.vue';
import CmsTextLinkBlock from '../../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import {Event} from '../../../../Interface/Event';
import {DateFormatter} from '../../../../Service/DateFormatter';
import {JsonLdService} from '../../../../Service/JsonLdService';
import Headline from '../../Headline/Headline.vue';
import NewsAndEventsDetailMixin from '../NewsAndEventsDetailMixin';

export default {
    name: 'EventDetail',
    mixins: [NewsAndEventsDetailMixin],
    components: {LinkWrap, CmsTextLinkBlock, Headline, Icon},
    metaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags.concat([
                {'name': 'description', 'content': this.ogDescription, 'vmid': 't3-description'}
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.ogTitle)
            ]
        };
    },
    computed: {
        event(): Event
        {
            return this.context.initialState.response.data;
        },
        ogTitle(): string
        {
            return this.getOgTitle(this.event.headline);
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.event.text);
        },
        ogImage(): string
        {
            return this.getOgImage(this.event.images);
        }
    },
    methods: {
        renderDate(event: Event): string
        {
            let result = DateFormatter.format(event.date);

            if (event.dateEnd) {
                result += ' - ' + DateFormatter.format(event.dateEnd);
            }

            return result;
        }
    },
    created()
    {
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.event.headline);
        if (this.event.images) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE, this.event.images);
        }
        this.context.pageContext.pageMeta.setCanonical(this.event.link);
    }
};
